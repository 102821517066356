<!-- WARNING the "inputId" must be unique, otherwise the ng2FileSelect doesn't work properly -->
<label #labelTag for="{{ inputId }}">
    <button
        interacta-button
        type="button"
        [appearance]="appearance"
        [attr.aria-label]="label ?? tooltip"
        [bgColor]="bgColor"
        [disabled]="disabled"
        [icon]="icon"
        [label]="label"
        [matTooltip]="tooltip ?? ''"
        [matTooltipDisabled]="!tooltip"
        [shape]="shape"
        [size]="size"
        (click)="labelTag.click()"
    ></button>
</label>
<interacta-file-upload-input
    [acceptedMimeType]="acceptedMimeType"
    [inputId]="inputId"
    [uploader]="uploader"
    (fileSelected)="fileSelected.emit($event)"
/>
