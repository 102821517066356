import { Injectable, inject } from '@angular/core';
import {
    TipAction,
    TipCloseAction,
    TipService,
} from '@interacta-shared/feature-tip';
import { isDefined } from '@interacta-shared/util';
import { BoundedTipConfig } from '@modules/core/models/bounded-tip.model';
import { selectRouteState } from '@modules/core/store/app.selectors';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom, map, of, withLatestFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BoundedTipService {
    private readonly tipService = inject(TipService);
    private readonly store = inject(Store);

    openBoundedTip<A>(
        config: BoundedTipConfig<A>,
    ): Observable<TipAction<A> | TipCloseAction> {
        return this.tipService.openProTip({
            ...config,
            canOpen: (payload) =>
                firstValueFrom(
                    of(payload).pipe(
                        withLatestFrom(this.store.select(selectRouteState)),
                        map(
                            ([payload, route]) =>
                                !isDefined(payload) ||
                                (isDefined(route) &&
                                    payload.boundary.includes(route.appRoute)),
                        ),
                    ),
                ),
        });
    }
}
