import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    Appearance,
    ButtonColor,
    ButtonComponent,
    Shape,
    Size,
} from '@interacta-shared/ui';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import { uuid } from '@interacta-shared/util';
import { FileUploader } from 'ng2-file-upload';
import { FileUploadInputComponent } from '../file-upload-input/file-upload-input.component';

@Component({
    standalone: true,
    imports: [FileUploadInputComponent, ButtonComponent, MatTooltipModule],
    selector: 'interacta-local-file-select',
    templateUrl: './local-file-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalFileSelectComponent {
    @Input({ required: true }) uploader!: FileUploader;
    @Input() label?: string;
    @Input() tooltip?: string;
    @Input() icon?: InteractaIcon = 'paperclip';
    @Input() shape: Shape = 'rectangular';
    @Input() size: Extract<Size, 'large' | 'regular' | 'small'> = 'regular';
    @Input() appearance: Appearance = 'fill';
    @Input() acceptedMimeType?: string[];
    @Input() bgColor: ButtonColor = 'primary';
    @Input() disabled = false;

    @Output() fileSelected = new EventEmitter<File[]>();

    readonly inputId = `uploader-${uuid()}`;
}
