import { I18nPluralPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '@interacta-shared/ui-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Palette, Size } from '../../model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[interacta-chip-show-more][counter]',
    template: `
        <div
            class="flex max-w-max shrink-0 items-center rounded-2xl"
            [attr.aria-label]="
                counter
                    | i18nPlural
                        : {
                              '=1': 'UI.SHOW_MORE_UNCOUNT',
                              other: 'SHOW_MORE_COUNT'
                          }
                    | translate: { counter }
            "
            [ngClass]="{
                'bg-surface-B': bgColor === 'surface-B',
                'bg-surface-A': bgColor === 'surface-A',
                'py-4 pl-12': size === 'regular',
                'py-2 pl-8': size === 'small'
            }"
        >
            <span class="typo-xs">+{{ counter }}</span>
            <interacta-icon
                [icon]="'chevron-small-right'"
                [ngClass]="{
                    'size-24': size === 'regular',
                    'size-20': size === 'small'
                }"
            ></interacta-icon>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, IconComponent, I18nPluralPipe, TranslateModule],
})
export class ChipShowMoreComponent {
    @Input() counter!: number;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
}
